import React, {useEffect, useState} from "react";

import PlayerList from "../../components/players/PlayerList";
import SearchPlayers from "../../components/players/SearchPlayers";
import PlayerPagination from "../../components/players/PlayerPagination";
import Error from "../../components/general/Error";

import {PlayerSearchHitInterface} from "../../utils/interfaces";
import {fetchPlayerList} from "../../utils/fetchers";
import {playersEndpoint} from "../../utils/endpoints";

const HomePage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [morePlayersLoading, setMorePlayersLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const [players, setPlayers] = useState<PlayerSearchHitInterface[]>([])
    const [playerCount, setPlayerCount] = useState<number>(0)
    const [nextPlayersUrl, setNextPlayersUrl] = useState<string | null>(null)
    const [showPagination, setShowPagination] = useState<boolean>(true)

    useEffect(() => {
        document.title = "Global Tennis Score"
        if (players.length === 0) {
            fetchPlayerList(playersEndpoint).then(data => {
                setLoading(false)
                setPlayers(data.results)
                setPlayerCount(data.count)
                setNextPlayersUrl(data.next)
            })
                .catch(error => {
                    setLoading(false)
                    setError(true)
                    console.log(error)
                })
        }
    }, []);

    const updatePlayers = (players: PlayerSearchHitInterface[]) => {
        setPlayers(players)
    }

    const loadMorePlayers = () => {
        if (!nextPlayersUrl) {
            return
        }
        setMorePlayersLoading(true)
        fetchPlayerList(nextPlayersUrl).then(data => {
            setMorePlayersLoading(false)
            setPlayers(existingPlayers => [...existingPlayers, ...data.results])
            setNextPlayersUrl(data.next)

        }).catch(error => {
            setMorePlayersLoading(false)
            setError(true)
            console.log(error)
        })
    }

    const changePaginationVisibility = (visibility: boolean) => {
        setShowPagination(visibility)
    }

    return (
        <div>
            <SearchPlayers updatePlayers={updatePlayers} changePaginationVisibility={changePaginationVisibility}/>
            {loading ? (
                <div className="flex justify-center p-6">
                    <div
                        className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-500 rounded-full"
                        role="status" aria-label="loading">
                    </div>
                </div>
            ) : (
                <>
                    {error ? (
                        <Error message="Error loading players"/>
                    ) : (
                        <>
                            <PlayerList players={players} count={playerCount}/>
                            {showPagination ? <PlayerPagination loadMorePlayers={loadMorePlayers}
                                                                morePlayersLoading={morePlayersLoading}/> : <></>}
                        </>
                    )}
                </>
            )}
        </div>
    )
}


export default HomePage