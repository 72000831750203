import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {fetchCollegeDetail} from "../../utils/fetchers";
import Error from "../../components/general/Error";
import {CollegeDetailInterface} from "../../utils/interfaces";
import {Link} from "react-router-dom";

const CollegeDetailPage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)

    const [college, setCollege] = useState<CollegeDetailInterface | null>(null)

    const {id} = useParams()

    useEffect(() => {
        if (id) {
            fetchCollegeDetail(id).then(data => {
                setLoading(false)
                if (!data) {
                    setError(true)
                } else {
                    setCollege(data)
                    document.title = data.name + " - GTS"
                }

            }).catch(error => {
                setLoading(false)
                setError(true)
                console.log(error)
            })
        }
    }, [id]);

    return (
        <>
            {loading ? <div>Loading...</div> : <></>}
            {error ? (<Error message="Error loading college"/>) : (
                <>{college ? (
                    <div>
                        <h1 className="text-xl font-bold">{college.name} - {college.power_rating}</h1>
                        <p>{college.division_display} - {college.conference} - {college.gender_display} - {college.is_updated ? 'Updated' : 'Not updated'}</p>
                        <h2 className="text-lg font-bold mt-6">Roster - {college.roster_size} players</h2>
                        {college.roster.map(player => (
                            <div>
                                <Link to={`/players/${player.id.toString()}`}>
                                    {player.name} - {player.rating} - {player.predicted_utr} - {player.player_class}
                                </Link>
                            </div>
                        ))}
                        <h2 className="text-lg font-bold mt-6">Coaches</h2>
                        {college.coaches.map(coach => (
                            <p>{coach.name} - {coach.role} - {coach.email}</p>
                        ))}
                    </div>
                ) : <></>}</>
            )}
        </>
    )
}

export default CollegeDetailPage