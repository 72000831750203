import React from "react"
import {Link} from "react-router-dom";

import {PlayerSearchHitInterface} from "../../utils/interfaces";
import arrowPath from "../../assets/back-arrow.svg"

interface PlayerListProps {
    players: PlayerSearchHitInterface[]
    count: number
}

const PlayerList = ({players, count}: PlayerListProps) => {
    return (
        <div className="mx-5">
            <div className="py-2 border-indigo-500 border-b-2 text-gray-600 flex justify-between items-center">
                <p>Showing {players.length} of {count} players</p>
                <div className="flex items-center mr-1 md:mr-3 lg:mr-6">
                    <p className="w-20 text-center">GTS</p>
                    <img src={arrowPath} alt="Forward arrow" className="size-7 rotate-180 invisible"/>
                </div>
            </div>
            {players.length > 0 ? (
                <div className="flex flex-col">
                    {players.map(p => (
                        <div key={p.id} className="border-indigo-500 border-b hover:bg-gray-100">
                            <Link to={`/players/${p.id.toString()}`}
                                  className="flex justify-between items-center py-3 px-1 md:px-3 lg:px-6">
                                <div>
                                    <p className="text-lg">{p.name}</p>
                                    <p className="text-sm font-light text-gray-600">{p.nationality ? `${p.nationality} | ` : ''}{p.gender.toUpperCase()} {p.age ? `| ${p.age}` : ''}</p>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-xl font-medium w-20 text-center">{p.rating}</p>
                                    <img src={arrowPath} alt="Forward arrow" className="size-7 rotate-180"/>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-lg mt-2">No results found</p>
            )}
        </div>
    )
}

export default PlayerList