import React from "react";

interface CollegePaginationProps {
    loadMoreColleges(): void
    moreCollegesLoading: boolean
}

const CollegePagination = ({loadMoreColleges, moreCollegesLoading}: CollegePaginationProps) => {
    return (
        <div className="flex justify-center items-center p-5">
            <button
                className="flex justify-center items-center gap-3 bg-gradient-to-r from-indigo-500 to-blue-500 text-gray-100 px-3 py-3 text-lg rounded"
                onClick={loadMoreColleges}>
                <p>Load More Colleges</p>
                {moreCollegesLoading ? (
                    <div
                        className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-gray-300 rounded-full"
                        role="status" aria-label="loading">
                    </div>
                ) : <></>}
            </button>
        </div>
    )
}

export default CollegePagination